
import React from 'react'

export const serumData = [
  { id: 1, GENERIC_NAME: "ACECLOFENAC & MENTHOL SPRAY", STRENGTH: "1% W/W+ 5% W/W", CATEGORY: "TOPICAL SPRAY" },
  { id: 2, GENERIC_NAME: "DICLOFENAC TOPICAL SPRAY", STRENGTH: "2.32%/4.64%W/V", CATEGORY: "TOPICAL SPRAY" },
  { id: 3, GENERIC_NAME: "LIODOCAINE + ETHANOL SPRAY", STRENGTH: "10%W/V", CATEGORY: "TOPICAL SPRAY" },
  { id: 4, GENERIC_NAME: "LIODOCAINE SPRAY", STRENGTH: "10%W/V", CATEGORY: "TOPICAL SPRAY" },
  { id: 5, GENERIC_NAME: "TERBINAFINE HYDROCHLORIDE TOPICAL SPRAY", STRENGTH: "1.0% W/W", CATEGORY: "TOPICAL SPRAY" },
  { id: 6, GENERIC_NAME: "MINOXIDIL + FINASTEROID TOPICAL SOLUTION", STRENGTH: "5% W/V + 0.1%W/V / 10%W/V + 0.1% W/V", CATEGORY: "TOPICAL SOLUTION" },
  { id: 7, GENERIC_NAME: "MINOXIDIL TOPICAL SOLUTION", STRENGTH: "2% /5%/ 10%W/V", CATEGORY: "TOPICAL SOLUTION" },
];




const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>STRENGTH</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>CATEGORY</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.STRENGTH}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.CATEGORY}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>SERUM/DROPS/SPRAYS</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={serumData} />
        </div>
      </div>

    </div>
  )
}



