// SearchService.js

import { toast } from "react-toastify";
import { capsuleData } from "../Products/Products/EnternalFormulations/EnternalCategories/Capsules/CapsuleData.js";
import { tabletData } from "../Products/Products/EnternalFormulations/EnternalCategories/Tablet/TabletData.js";
import { sachetData } from "../Products/Products/EnternalFormulations/EnternalCategories/Sachets.js";
import { lozengesData } from "../Products/Products/EnternalFormulations/EnternalCategories/Lozenges/LozengesData.js";
import { suppositoriesData } from "../Products/Products/EnternalFormulations/EnternalCategories/Suppositories/SuppositoriesData.js";
import { drypowderData } from "../Products/Products/ParentalFormulations/ParentalCategories/DryPowder/DryPowderData.js";
import { infusionData } from "../Products/Products/ParentalFormulations/ParentalCategories/Infusions/InfusionData.js";
import { liquidData } from "../Products/Products/ParentalFormulations/ParentalCategories/LiquidInjectables/LiquidInjectablesData.js";
import { contrastmediaData } from "../Products/Products/ParentalFormulations/ParentalCategories/ContrastMedia.js";
import { presyringesData } from "../Products/Products/ParentalFormulations/ParentalCategories/PrefilledSyringes.js";
import { inhalerData } from "../Products/Products/InhalationFormulations/InhalationalCategories/Inhalers/InhalersData.js";
import { nasalData } from "../Products/Products/InhalationFormulations/InhalationalCategories/NasalSprays.js";
import { repsuleData } from "../Products/Products/InhalationFormulations/InhalationalCategories/Repsules.js";
import { rotaData } from "../Products/Products/InhalationFormulations/InhalationalCategories/RotaCaps.js";
import { creamData } from "../Products/Products/TopicalFormulations/TopicalCategories/Cream.js";
import { lotionData } from "../Products/Products/TopicalFormulations/TopicalCategories/Lotion.js";
import { serumData } from "../Products/Products/TopicalFormulations/TopicalCategories/Serums.js";
import { eyeData } from "../Products/Products/OpthalmicProducts/OpthalmicCategories/Eye/EyeData.js";
import { implantData } from "../Products/Products/OpthalmicProducts/OpthalmicCategories/Implants.js";
import { intraocularData } from "../Products/Products/OpthalmicProducts/OpthalmicCategories/Intraocular.js";
import { optinstrumentData } from "../Products/Products/OpthalmicProducts/OpthalmicCategories/OpthalmicInstrument.js";
import { hospitalData } from "../Products/Products/Others/OtherCategories/Hospital/HospitalData.js";
import { surgicalData } from "../Products/Products/Others/OtherCategories/Surgical/SurgicalData.js";

export const searchProducts = (searchTerm) => {
  const allData = [
    ...capsuleData, 
    ...tabletData,
    ...sachetData,
    ...lozengesData,
    ...suppositoriesData,
    ...drypowderData,
    ...infusionData,
    ...liquidData,
    ...contrastmediaData,
    ...presyringesData,
    ...inhalerData,
    ...nasalData,
    ...repsuleData,
    ...rotaData,
    ...creamData,
    ...lotionData,
    ...serumData,
    ...eyeData,
    ...implantData,
    ...intraocularData,
    ...optinstrumentData,
    ...hospitalData,
    ...surgicalData,
  ];

  const filteredData = allData.filter((item) =>
    item.GENERIC_NAME.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (filteredData.length === 0) {
    toast.error("No results found!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    toast.success(`${filteredData.length} results found!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  return filteredData;
};
