import React from "react";
import "./Footer.css";
import logo from "../../assets/logo.png";

export default function Footer() {

  // const handleclick = () => {
  //   window.open("https://www.linkedin.com/in/-reena-verma/", "tab1");
  //   window.open("https://www.linkedin.com/in/gauravdev04/", "tab2");
  // };

  return (
    <div className="footer mt-[3vh] py-[2vh]">
      <div className="md:w-[90%] lg:w-[80%] mx-auto flex flex-col items-center md:items-start md:flex-row justify-between">
        <div>
          <img
            src={logo}
            className="h-[8vh] mb-[3vh] md:mb-[0vh] md:w-[40vh] lg:w-[13vw]"
            alt="logo"
          />
        </div>
        <div>
          <h1 className="text-[3vh] md:text-[2.5vh] lg:text-[3vh] font-bold text-[#404040] mb-[1vh] text-center md:text-start">
            Products
          </h1>
          <ul className="text-gray-600 text-center md:text-start mb-[3vh] md:mb-[0vh]">
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Enternal Foundation
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Parenteral Formutaions
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Inhalation Formutaions
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Topical (SKIN/HAIR) Formutaions
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Opthalmic & ENT Products
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Others
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-[3vh] md:text-[2.5vh] lg:text-[3vh] font-bold text-[#404040] mb-[1vh]text-center md:text-start">
            Company
          </h1>
          <ul className="text-gray-600 mb-[3vh] md:mb-[0vh] text-center md:text-start">
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              About Us
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Our Products
            </li>
            <li className="mt-[0.5vh] lg:text-[2.6vh] hover:underline">
              Contact Us
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-[3vh] md:text-[2.5vh] lg:text-[3vh] font-bold text-[#404040] mb-[1vh]text-center md:text-start mb-[3vh] md:mb-[0vh]">
            Follow us on
          </h1>
          <ul>
            <i class="fa-brands fa-square-instagram"></i>
            <i class="fa-brands fa-linkedin"></i>
            <i class="fa-brands fa-square-twitter"></i>
            <i class="fa-brands fa-square-whatsapp"></i>
          </ul>
        </div>
      </div>
      <hr className="mt-[3vh] h-[2px]" />
      <div className="w-[90%]  md:w-[80%] mx-auto mt-[2vh] flex flex-col md:flex-row justify-between">
        <p className=" text-gray-500 text-[2vh] md:text-[2.2vh] mb-[2vh] md:mb-[0vh] text-center md:text-start">
          © 2023 Pelikas Healthcare. All Rights Reserved.
        </p>
        <p className="text-[1.8vh] md:text-[2.2vh] text-[#404040] text-center md:text-start">
            Designed and Developed by
            <span className="ml-[1vh]">
                <a href="https://www.linkedin.com/in/gauravdev04/" target="_blank" rel="noreferrer">
                    <i class="creditlinks fa-solid fa-g"></i>
                </a>
            </span>
            &
            <span className="ml-[1vh]">
                <a href="https://www.linkedin.com/in/-reena-verma/" target="_blank" rel="noreferrer">
                  <i class="creditlinks fa-solid fa-r"></i>
                </a>
            </span>
        </p>

      </div>
    </div>
  );
}
