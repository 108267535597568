export const eyeData = [
        { id: 1, GENERIC_NAME: "Ciprofloxacin Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 2, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Ciprofloxacin 0.3% Eye drops", PACK_STYLE: "10ml" },
        { id: 3, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Gentamycin 0.3% Eye/Ear Drop", PACK_STYLE: "10ml" },
        { id: 4, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Moxifloxacin 0.5% Eye Drops", PACK_STYLE: "5ml" },
        { id: 5, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Neomycin Sulphate 0.5% Eye/Ear Drops", PACK_STYLE: "10ml" },
        { id: 6, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Ofloxacin 0.3% Eye Drops", PACK_STYLE: "10ml" },
        { id: 7, GENERIC_NAME: "Dexamethasone Sodium Phosphate 0.1% + Tobramycin Sulphate 0.3% Eye Drops", PACK_STYLE: "5ml & 10ml" },
        { id: 8, GENERIC_NAME: "Dexamethasone Sodium Phosphate Eye Drops", STRENGTH: "0.1%w/v", PACK_STYLE: "10ml" },
        { id: 9, GENERIC_NAME: "Flubiprofen Sodium Ophthalmic Solution", STRENGTH: "0.03%w/v", PACK_STYLE: "5ml" },
        { id: 10, GENERIC_NAME: "Fluconazole Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 11, GENERIC_NAME: "Gentamicin Sulfate Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 12, GENERIC_NAME: "Hydroxy Propyl Methyl Cellulose Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 13, GENERIC_NAME: "Ketorolac Ophthalmic Solution", STRENGTH: "0.4%w/v", PACK_STYLE: "5ml" },
        { id: 14, GENERIC_NAME: "Levofloxacin Eye Drops", STRENGTH: "0.5%w/v", PACK_STYLE: "10ml" },
        { id: 15, GENERIC_NAME: "Moxifloxacin 0.5% + Ketorolac 0.5% Eye Drops", PACK_STYLE: "10ml" },
        { id: 16, GENERIC_NAME: "Moxifloxacin Ophthalmic Solution", STRENGTH: "0.5%w/v", PACK_STYLE: "10ml" },
        { id: 17, GENERIC_NAME: "Norfloxacin Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 18, GENERIC_NAME: "Ofloxacin Eye Drops", STRENGTH: "0.3%w/v", PACK_STYLE: "10ml" },
        { id: 19, GENERIC_NAME: "Olopatadine Hydrochloride Solution", STRENGTH: "0.1% w/v", PACK_STYLE: "10ml" },
        { id: 20, GENERIC_NAME: "Polyethylene Glycol 400 4mg + Propylene Glycol 3mg Eye Drops", PACK_STYLE: "10ml" },
        { id: 21, GENERIC_NAME: "Sodium Carboxy Methyl Cellulose Eye Drops", STRENGTH: "0.5%w/v", PACK_STYLE: "10ml" },
        { id: 22, GENERIC_NAME: "Sodium Carboxy Methyl Cellulose Eye Drops", STRENGTH: "1%w/v", PACK_STYLE: "10ml" },
        { id: 23, GENERIC_NAME: "Sodium Chromoglicate Eye Drops", STRENGTH: "2%w/v", PACK_STYLE: "10ml" },
        { id: 24, GENERIC_NAME: "Timolol Maleate Eye Drops", STRENGTH: "0.5%w/v", PACK_STYLE: "5ml" },
        { id: 25, GENERIC_NAME: "Tobramycin Ophthalmic Solution", STRENGTH: "0.3%w/v", PACK_STYLE: "5ml & 10ml" },
        { id: 26, GENERIC_NAME: "Gatifloxacin&prednisolone opthalmic solution", PACK_STYLE: "5 ml" },
        { id: 27, GENERIC_NAME: "Bimatoprost opthalmic solution", PACK_STYLE: "3 ml" },
        { id: 28, GENERIC_NAME: "Brimonidine tartrate & timolol maleate opthalmic solution", PACK_STYLE: "5 ml" },
        { id: 29, GENERIC_NAME: "Ciprofloxacin hcl,chlorpheniramine maleate & zinc sulphate eye drop", PACK_STYLE: "10 ml" },
        { id: 30, GENERIC_NAME: "Dexamethasone sodium phosphate & chloramphenicol", PACK_STYLE: "10 ml" },
        { id: 31, GENERIC_NAME: "Ofloxacin & dexamethasone eye/ear drop", PACK_STYLE: "10 ml" },
        { id: 32, GENERIC_NAME: "Gentamicin sulphate,chlorpheniramine maleate & zinc sulphate eye drop", PACK_STYLE: "10 ml" },
        
]
export default eyeData;