export const hospitalData = [
    { id: 1, GENERIC_NAME: "NEBULIZER MACHINE", PACK_STYLE: "1 PCS" },
    { id: 2, GENERIC_NAME: "MESH NEBULIZER", PACK_STYLE: "1 PCS" },
    { id: 3, GENERIC_NAME: "PULSE OXIMETER", PACK_STYLE: "BOX OF 10 PCS" },
    { id: 4, GENERIC_NAME: "DIGITAL THERMOMETER", PACK_STYLE: "BOX OF 10 PCS" },
    { id: 5, GENERIC_NAME: "AIR BED", PACK_STYLE: "1 PCS" },
    { id: 6, GENERIC_NAME: "BP MONITOR", PACK_STYLE: "1 PCS" },
    { id: 7, GENERIC_NAME: "VAPORIZER", PACK_STYLE: "1 PCS" },
    { id: 8, GENERIC_NAME: "FETAL DOPPLER", PACK_STYLE: "1 PCS" },
    { id: 9, GENERIC_NAME: "SUCTION MACHINE", PACK_STYLE: "BOX OF 1 PCS" },
    { id: 10, GENERIC_NAME: "BODY MASSAGER", PACK_STYLE: "BOX OF 1 PCS" },
    { id: 11, GENERIC_NAME: "BREAST PUMP : MANUAL", PACK_STYLE: "BOX OF 1 PCS" },
    { id: 12, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – PLASTIC", PACK_STYLE: "1 PCS" },
    { id: 13, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – MANUAL", PACK_STYLE: "1 PCS" },
    { id: 14, GENERIC_NAME: "NEEDLE SYRINGE DESTROYER – METAL", PACK_STYLE: "1 PCS" },
    { id: 15, GENERIC_NAME: "STATURE METER", PACK_STYLE: "-" },
    { id: 16, GENERIC_NAME: "SPIROMETER", PACK_STYLE: "-" },
    { id: 17, GENERIC_NAME: "CONTROL PENCIL", PACK_STYLE: "PKT OF 10 PCS" },
    { id: 18, GENERIC_NAME: "INFUSION PUMP BYS-820", PACK_STYLE: "1PC" },
    { id: 19, GENERIC_NAME: "CHANNEL SYRINGE PUMP BYZ-810D", PACK_STYLE: "1PC" },
    { id: 20, GENERIC_NAME: "OXYPLUS 240ST TABLE TOP PULSE OXIMETER", PACK_STYLE: "1PC" },
    { id: 21, GENERIC_NAME: "SPO2 SENSOR", PACK_STYLE: "1PC" },
    { id: 22, GENERIC_NAME: "DIGITAL 3 CHANNEL ECG MACHINE", PACK_STYLE: "1PC" },
    { id: 23, GENERIC_NAME: "CONTACT INFRARED FOREHEAD THERMOMETER FOR HOSPITAL", PACK_STYLE: "1PC" },
    { id: 24, GENERIC_NAME: "12 CHANNEL ECG MACHINE WITH GLASGOW ALGORITHM", PACK_STYLE: "1PC" },
    { id: 25, GENERIC_NAME: "PORTABLE LIGHT-WEIGHT FETAL MONITOR WITH COLOR DISPLAY", PACK_STYLE: "1PC" },
    { id: 26, GENERIC_NAME: "SINGLE HOSE CUFF ADULT 25CM TO 35 CM GREY", PACK_STYLE: "1PC" },
    { id: 27, GENERIC_NAME: "LUMIS 150 VPAP ST", PACK_STYLE: "1PC" },
    { id: 28, GENERIC_NAME: "BMC RESMART GII AUTO BIPAP WITH HUMIDIFIER BREATHING RESPIRATORY EXERCISER", PACK_STYLE: "1PC" },
    { id: 29, GENERIC_NAME: "BPC METALLIC FLOW METER WITH HUMIDIFIER BOTTLE", PACK_STYLE: "1PC" },
];


export default hospitalData;