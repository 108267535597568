import React from "react";
import "./Services.css";
import { airCargo, seaCargo } from "../Services/ServiceIcon";
import acargo from "../../assets/aircargo.webp";
import scargo from "../../assets/seacargo.jpg";

export default function Services() {
  return (
    <div>
      <h1 className="text-center text-[4vh]  font-semibold text-[#001F8E] mt-[8vh] mb-[2vh]">
        International Air Cargo
      </h1>
      <div className="mx-auto shadow-lg rounded-lg mb-[8vh] flex flex-col-reverse w-[100vw] px-[2vh] py-[8vh] justify-center bg-gradient-to-r from-[#e7fafd] to-[#7086d758] md:flex-row md:w-[80vw] lg:flex-row lg:w-[80vw]">
        <div className="flex flex-row flex-wrap mx-auto w-[100%] md:w-[70%] lg:w-[80%] ">
          {airCargo.map((service, index) => (
            <div
              key={index}
              className="bg-white  m-[1vh] p-[2vh] border shadow-md border-white rounded-lg w-[20%] h-[60%] md:w-[24%] md:h-[15%] lg:w-[16%] lg:h-[26%] flex justify-center items-center"
            >
              <img src={service.image} alt={service.name} />
            </div>
          ))}
        </div>

        <div>
          <img src={acargo} className="mx-auto w-[100%] " alt="cargo" />
        </div>
      </div>

      <h1 className="text-center text-[4vh] font-semibold text-[#001F8E] my-[2vh]">
        International Sea Cargo and Courier
      </h1>
      <div className="mx-auto mb-[8vh] rounded-lg shadow-lg flex flex-col w-[100vw] py-[8vh] justify-center bg-gradient-to-r from-[#e7fafd] to-[#7086d758] md:flex-row md:w-[80vw] lg:flex-row lg:w-[80vw]">
        <img
          src={scargo}
          className=" w-[90%] mx-auto rounded-[2vh] md:mx-[2vh]  lg:mx-[3vh] md:w-[50%] lg:w-[55%] sm:mb-[5vh]"
          alt="cargo"
        />

        <div className="flex flex-row flex-wrap w-[100%] sm:m-[2vh] md:w-[70%] lg:w-[80%] ">
          {seaCargo.map((service, index) => (
            <div
              key={index}
              className="bg-white mx-auto m-[1vh] border shadow-md p-[2vh] border-white rounded-lg w-[22%] h-[5%] md:w-[24%] md:h-[20%] lg:w-[22%] lg:h-[22%]   flex justify-center items-center"
            >
              <img src={service.image} alt={service.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
