import React from 'react'

export const sachetData = [
  { id: 1, GENERIC_NAME: "CHOLECALCIFEROL (VIT D3)", STRENGTH: "60,000 IU", PACKING: "1GM" },
  { id: 2, GENERIC_NAME: "PREBIOTIC + PROBIOTIC", STRENGTH: "-", PACKING: "1GM" },
  { id: 3, GENERIC_NAME: "L ARGININE SACHET 3 GM", STRENGTH: "3000MG", PACKING: "8.5GM" },
  { id: 4, GENERIC_NAME: "L-ARGININE +PROANTHOCYANIDIN", STRENGTH: "-", PACKING: "6.5GM" },
  { id: 5, GENERIC_NAME: "AMINO ACIDS", STRENGTH: "-", PACKING: "10GM" },
  { id: 6, GENERIC_NAME: "MESALAMINE", STRENGTH: "2000MG", PACKING: "2GM" },
  { id: 7, GENERIC_NAME: "ORAL REHYDRATION SALTS (ORS)", STRENGTH: "-", PACKING: "4.4GM, 21GM +" },
  { id: 8, GENERIC_NAME: "DICLOFENAC", STRENGTH: "-", PACKING: "1GM" },
  { id: 9, GENERIC_NAME: "TESTESTERONE", STRENGTH: "-", PACKING: "1GM" },
  { id: 10, GENERIC_NAME: "COLLAGEN PEPTIDE", STRENGTH: "-", PACKING: "1GM" },
];



const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>STRENGTH</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACKING</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.STRENGTH}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACKING}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>Sachets</h1>
      <div className="App">
        <Table data={sachetData} />
      </div>

    </div>
  )
}


