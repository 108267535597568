import React from 'react'

export const nasalData = [
  { id: 1, GENERIC_NAME: "AZELASTINE HYDROCHLORIDE & FLUTICASONE PROPIONATE NASAL SPRAY 140 MCG + 50 MCG" },
  { id: 2, GENERIC_NAME: "AZELASTINE HYDROCHLORIDE AND FLUTICASONE FUROATE NASAL SPRAY" },
  { id: 3, GENERIC_NAME: "AZELASTINE HYDROCHLORIDE NASAL SPRAY 0.1% W/V" },
  { id: 4, GENERIC_NAME: "BECLOMETASONE AQUEOUS NASAL SPRAY 50 MCG" },
  { id: 5, GENERIC_NAME: "BUDESONIDE AQUEOUS NASAL SPRAY 200 MCG/100 MCG" },
  { id: 6, GENERIC_NAME: "CALCITONIN (SALMON) NASAL SPRAY 200 IU" },
  { id: 7, GENERIC_NAME: "CICLESONIDE AQUEOUS NASAL SPRAY" },
  { id: 8, GENERIC_NAME: "DESMOPRESSIN NASAL SPRAY SOLUTION 0.01 %W/V" },
  { id: 9, GENERIC_NAME: "FLUNISOLIDE NASAL SPRAY" },
  { id: 10, GENERIC_NAME: "FLUTICASONE FUROATE & AZELASTINE HYDROCHLORIDE NASAL SPRAY 27.5 MCG + 140 MCG" },
  { id: 11, GENERIC_NAME: "FLUTICASONE FUROATE NASAL SPRAY 27.5 MCG" },
  { id: 12, GENERIC_NAME: "FLUTICASONE PROPIONATE NASAL SPRAY 50 MCG" },
  { id: 13, GENERIC_NAME: "LOTEPREDNOL ETABONATE NASAL SPRAY" },
  { id: 14, GENERIC_NAME: "MOMETASONE AQUEOUS NASAL SPRAY 50 MCG" },
  { id: 15, GENERIC_NAME: "MOMETASONE NASAL SPRAY" },
  { id: 16, GENERIC_NAME: "OLOPATADINE NASAL SPRAY" },
  { id: 17, GENERIC_NAME: "OXYMETAZOLINE HYDROCHLORIDE NASAL SOLUTION 0.05 % /0.025% W/V" },
  { id: 18, GENERIC_NAME: "SODIUM CHLORIDE NASAL SPRAY 0.65% W/V" },
  { id: 19, GENERIC_NAME: "SUMATRIPTAN NASAL SPRAY" },
  { id: 20, GENERIC_NAME: "SYNTHETIC SALMON CALCITONIN NASAL SPRAY" },
  { id: 21, GENERIC_NAME: "XYLOMETAZOLINE HCL & IPRATROPIUM BROMIDE NASAL SPRAY" },
  { id: 22, GENERIC_NAME: "XYLOMETAZOLINE HYDROCHLORIDE NASAL SPRAY 0.1% W/V / 0.05% W/V" },
  { id: 23, GENERIC_NAME: "ZOLMITRIPTAN NASAL SPRAY 5 MG" },
];




const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Sachets() {
  return (


    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>NASAL SPRAYS</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={nasalData} />
        </div>
      </div>

    </div>
  )
}

