export const lozengesData = [
   
{
    "id": 1,
    GENERIC_NAME: "AMYLMETACRESOL DICHLOROBENZYL LOZENGES (ANTISEPTIC LOZENGES)",
    FLAVOURS: "HONEY, LEMON, LITCHI, LEMONGRASS, STRAWBERRY, ORANGE, BLACK CURRENT, GINGER, MANGO, PAN MASALA, PINEAPPLE, CHEERY, GUAVA",
    PACK_STYLE: "BLISTER, STRS, PILLOW PACKS",
    PACKING: "BLISTERS: 2 LOZ, 6 LOZ, 8 LOZ, 12 LOZ STRS: 2 LOZ PILLOW PACKS: 50S, 100S, 150S, 200S JARS"
  },
  {
    "id": 2,
    GENERIC_NAME: "MENTHOL-EUCALYPTUS LOZENGES (SOOTHING LOZENGES)",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 3,
    GENERIC_NAME: "BENZOCAINE-MENTHOL LOZENGES (ANESTHETIC LOZENGES)",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 4,
    GENERIC_NAME: "DEXTROMETHROPHAN HYDROBROMIDE (ANTI-TUSSIVE LOZENGES)",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 5,
    GENERIC_NAME: "NICOTINE BASED LOZENGES (TO HELP QUIT SMOKING ADDICTION)",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 6,
    GENERIC_NAME: "TADALAFIL LOZENGES",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 7,
    GENERIC_NAME: "VITAMIN C + ZINC BASED LOZENGES",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 8,
    GENERIC_NAME: "AYURVEDIC LOZENGES",
    FLAVOURS: "Not specified",
    PACK_STYLE: "Not specified",
    PACKING: "Not specified"
  },
  {
    "id": 9,
    GENERIC_NAME: "OTHERS ++",
    FLAVOURS: "OTHERS ++",
    PACK_STYLE: "OTHERS ++",
    PACKING: "OTHERS ++"
  }
  
  // ... Additional data can be added here ...
  
    
]

export default lozengesData;