import React from "react";

import ProductMain from '../../../../elements/ProductMain';

const medicines = [
  {
    subtitle:"Repsules",
    subproducts:[
      {
        name:"Mometasone / Formoterol",
        image: require("../../../../assets/inhalation/repsule1.jpg")  
      },
      {
        name:"Salbutamol Nebulizer Solution",
        image: require("../../../../assets/inhalation/repsule2.jpg")  

      },
      {
        name:"Budesonide Nebuliser Suspension",
        image: require("../../../../assets/inhalation/repsule3.jpg")  
  
      },
      {
        name:"Tiotropium Respiratory Suspension",
        image: require("../../../../assets/inhalation/repsule4.jpg")  
 
      }
      
    ]
  },
  {
    subtitle:"Nasal Sprays",
    subproducts:[
      {
        name:"Ciclesonide Aqueous Nasal Spray",
        image: require("../../../../assets/inhalation/nasal1.jpg")  
 
      },
      {
        name:"Flunisolide Nasal Spray",
        image: require("../../../../assets/inhalation/nasal2.jpg")  
 
      },
      {
        name:"Mometasone Nasal Spray",
        image: require("../../../../assets/inhalation/nasal3.jpg")  
 
      },
      {
        name:"Olopatadine Nasal Spray",
        image: require("../../../../assets/inhalation/nasal4.jpg")  
 
      }
      
    ]
  }
]

export default function EnterFormulations() {
  return (
    <div>
      <h1 className="bg-green-200 p-[1vh] px-[3vh] text-[2.5vh] lg:text-[3vh]">
      InhalationalFormulations
      </h1>
      {medicines.map((medicine,index)=>(
        <ProductMain 
          key={index}
          subtitle={medicine.subtitle}
          subcategories={medicine.subproducts}
        />
      ))}  
    </div>
  );
}
