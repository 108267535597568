import React,{useState} from 'react'
import './Products.css';
import SideBar from './SideBar/SideBar';

import EnterFormulations from './Products/EnternalFormulations/EnterFormulations';
import Tablet from './Products/EnternalFormulations/EnternalCategories/Tablet/Tablet';
import Capsules from './Products/EnternalFormulations/EnternalCategories/Capsules/Capsules';
import Sachets from './Products/EnternalFormulations/EnternalCategories/Sachets';
import Lozenges from './Products/EnternalFormulations/EnternalCategories/Lozenges/Lozenges';
import Suppositories from './Products/EnternalFormulations/EnternalCategories/Suppositories/Suppositories';

import ParentalFormulations from './Products/ParentalFormulations/ParentalFormulations';
import DryPowder from './Products/ParentalFormulations/ParentalCategories/DryPowder/DryPowder';
import Infusions from './Products/ParentalFormulations/ParentalCategories/Infusions/Infusions';
import LiquidInjectables from './Products/ParentalFormulations/ParentalCategories/LiquidInjectables/LiquidInjectables';
import PrefilledSyringes from './Products/ParentalFormulations/ParentalCategories/PrefilledSyringes';
import ContrastMedia from './Products/ParentalFormulations/ParentalCategories/ContrastMedia';

import InhalationalFormulations from './Products/InhalationFormulations/InhalationalFormulations';
import Inhalers from './Products/InhalationFormulations/InhalationalCategories/Inhalers/Inhalers';
import NasalSprays from './Products/InhalationFormulations/InhalationalCategories/NasalSprays';
import Repsules from './Products/InhalationFormulations/InhalationalCategories/Repsules';
import RotaCaps from './Products/InhalationFormulations/InhalationalCategories/RotaCaps'

import TopicalFormulations from './Products/TopicalFormulations/TopicalFormulations';
import Cream from './Products/TopicalFormulations/TopicalCategories/Cream';
import Lotion from './Products/TopicalFormulations/TopicalCategories/Lotion';
import Serums from './Products/TopicalFormulations/TopicalCategories/Serums';

import OpthalmicProducts from './Products/OpthalmicProducts/OpthalmicProducts';
import Eye from './Products/OpthalmicProducts/OpthalmicCategories/Eye/Eye';
import Implants from './Products/OpthalmicProducts/OpthalmicCategories/Implants';
import Intraocular from './Products/OpthalmicProducts/OpthalmicCategories/Intraocular';
import OpthalmicInstrument from './Products/OpthalmicProducts/OpthalmicCategories/OpthalmicInstrument';

import Others from './Products/Others/Others';
import Hospital from './Products/Others/OtherCategories/Hospital/Hospital';
import Surgical from './Products/Others/OtherCategories/Surgical/Surgical';

export default function Products() {
  const [selectedTab, setSelectedTab] = useState('enternal-formulations');
  
  const handleTabSelect = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <div className='w-[90%] mx-auto my-[4vh] mb-[10vh]'>
      <div className='flex flex-col gap-y-[5vh] md:flex-row justify-between'>

        <SideBar  onTabSelect={handleTabSelect} />
        
        <div className='product-section w-[100%] md:w-[73%] bg-[#e9f8fc] p-[2vh]'>

          {/* EnterFormulations */}
          {selectedTab === 'enternal-formulations' && <EnterFormulations />}
          {selectedTab === 'enternal-formulations/tablets' && <Tablet />}
          {selectedTab === 'enternal-formulations/capsules' && <Capsules />}
          {selectedTab === 'enternal-formulations/sachets' && <Sachets />}
          {selectedTab === 'enternal-formulations/lozenges' && <Lozenges />}
          {selectedTab === 'enternal-formulations/suppositories' && <Suppositories />}
          
          {/* ParentFormulations */}
          {selectedTab === 'parental-formulations' && <ParentalFormulations/>}
          {selectedTab === 'parental-formulations/dry-powder-injectables' && <DryPowder/>}
          {selectedTab === 'parental-formulations/liquid-injectables' && <LiquidInjectables/>}
          {selectedTab === 'parental-formulations/prefilled-syringes' && <PrefilledSyringes/>}
          {selectedTab === 'parental-formulations/infusions' && <Infusions/>}
          {selectedTab === 'parental-formulations/contrast-media' && <ContrastMedia/>}

          {/* Inhalational Formulations */}
          {selectedTab === 'inhalational-formulations' && <InhalationalFormulations/>}
          {selectedTab === 'inhalational-formulations/repsules' && <Repsules/>}
          {selectedTab === 'inhalational-formulations/rotacaps' && <RotaCaps/>}
          {selectedTab === 'inhalational-formulations/inhalers' && <Inhalers/>}
          {selectedTab === 'inhalational-formulations/nasal-sprays' && <NasalSprays/>}

          {/* TopicalFormulations */}
          {selectedTab === 'topical-formulations' && <TopicalFormulations/>}
          {selectedTab === 'topical-formulations/creams' && <Cream/>}
          {selectedTab === 'topical-formulations/lotions' && <Lotion/>}
          {selectedTab === 'topical-formulations/serums' && <Serums/>}

          {/* Opthalmic Products */}
          {selectedTab === 'opthalmic-products' && <OpthalmicProducts/>}
          {selectedTab === 'opthalmic-products/eye-ear' && <Eye/>}
          {selectedTab === 'opthalmic-products/implants' && <Implants/>}
          {selectedTab === 'opthalmic-products/intraocular-solutions' && <Intraocular/>}
          {selectedTab === 'opthalmic-products/consumables' && <OpthalmicInstrument/>}

          {/* Others */}
          {selectedTab === 'others' && <Others/>}
          {selectedTab === 'others/surgical' && <Surgical/>}
          {selectedTab === 'others/hospital' && <Hospital/>}

        </div>
        
      </div>
    </div>
  )
}
