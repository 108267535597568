import React from 'react'

export const repsuleData = [
  { id: 1, GENERIC_NAME: "AMBROXOL HYDROCHLORIDE RESPIRATOR SOLUTION", PACK_STYLE: "2ML" },
  { id: 2, GENERIC_NAME: "BUDESONIDE NEBULISER SUSPENSION", PACK_STYLE: "2ML" },
  { id: 3, GENERIC_NAME: "BUDESONIDE RESPIRATORY SUSPENSION 0.5MG / 1.0MG", PACK_STYLE: "2ML / 2.5ML" },
  { id: 4, GENERIC_NAME: "FLUTICASONE PROPIONATE & SALMETEROL", PACK_STYLE: "2ML/2.5ML" },
  { id: 5, GENERIC_NAME: "FLUTICASONE PROPIONATE NEBULISER SOLUTION", PACK_STYLE: "2ML" },
  { id: 6, GENERIC_NAME: "FLUTICASONE PROPIONATE RESPIRATORY SUSPENSION 0.5MG / 2MG", PACK_STYLE: "2ML / 2.5ML" },
  { id: 7, GENERIC_NAME: "FORMOTEROL FUMARATE 20MCG & BUDESONIDE 0.5MG REPIRATOR SUSPENSION 20MCG + 0.5MG / 20MCG + 1MCG", PACK_STYLE: "2ML" },
  { id: 8, GENERIC_NAME: "GLYCOPYRRONIUM INHALATION SOLUTION 25 MCG", PACK_STYLE: "2ML" },
  { id: 9, GENERIC_NAME: "IPRATROPIUM BROMIDE & LEVOSULBUTAMOL RESPIRATORY SOLUTION", PACK_STYLE: "2ML/2.5ML" },
  { id: 10, GENERIC_NAME: "IPRATROPIUM BROMIDE RESPIRATORY SOLUTION", PACK_STYLE: "2ML / 2.5ML" },
  { id: 11, GENERIC_NAME: "IPRETROPIUM BROMIDE & FENOTEROL", PACK_STYLE: "2ML / 2.5ML" },
  { id: 12, GENERIC_NAME: "LEVOSALBUTAMOL 0.63MG & IPRATROPIUM BROMIDE 500MCG RESPIRATORY SOLUTION", PACK_STYLE: "2.5ML" },
  { id: 13, GENERIC_NAME: "LEVOSALBUTAMOL HYDROCHLORIDE & BUDESONIDE INHALATION SUSPENSION", PACK_STYLE: "2ML" },
  { id: 14, GENERIC_NAME: "LEVOSULBUTAMOL RESPIRATORY SOLUTION 0.31MG / 0.63MG / 1.125MG", PACK_STYLE: "2ML / 2.5ML" },
  { id: 15, GENERIC_NAME: "MOMETASONE & FORMOTEROL", PACK_STYLE: "2ML / 2.5ML" },
  { id: 16, GENERIC_NAME: "SALBUTAMOL NEBULIZER SOLUTION", PACK_STYLE: "2.5ML" },
  { id: 17, GENERIC_NAME: "SODIUM CHLORIDE INHALATION SOLUTION USP 3% / 7% W/V", PACK_STYLE: "4ML" },
  { id: 18, GENERIC_NAME: "STERILE WATER FOR INJECTIONS IP", PACK_STYLE: "5ML, 10ML" },
  { id: 19, GENERIC_NAME: "SULBUTAMOL & BUDESONIDE RESPIRATORY SUSPENSION 1MG + 0.5MG / 2MG + 0.5MG / 2MG + 1MG", PACK_STYLE: "2ML / 2.5ML" },
  { id: 20, GENERIC_NAME: "SULBUTAMOL RESPIRATORY SOLUTION", PACK_STYLE: "2ML / 2.5ML" },
  { id: 21, GENERIC_NAME: "TIOTROPIUM RESPIRATORY SUSPENSION", PACK_STYLE: "2ML / 2.5ML" },
];



const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACK_STYLE</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACK_STYLE}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Repsules() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>REPSULES</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={repsuleData} />
        </div>
      </div>

    </div>
  )
}

