

import React from 'react'

export const rotaData = [
  { id: 1, GENERIC_NAME: "BECLOMETASONE (400MCG) + FORMOTEROL (6MCG)", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 2, GENERIC_NAME: "FORMOTEROL FUMARATE 6 MCG & BUDESONIDE 100 MCG DRY POWDER FOR INHALATION", PACK_STYLE: "1X30 CAPS IN HDPE BOTTLE" },
  { id: 3, GENERIC_NAME: "IPRATROPIUM (40MCG)", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 4, GENERIC_NAME: "LEVOSALBUTAMOL (100MCG) + BECLOMETASONE (200MCG)", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 5, GENERIC_NAME: "SALBUTAMOL POWDER FOR INHALATION 200 MCG", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 6, GENERIC_NAME: "SALMETEROL (50MCG)", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 7, GENERIC_NAME: "SALMETEROL 50 MCG & FLUTICASONE PROPIONATE 250 MCG DRY POWDER FOR INHALATION", PACK_STYLE: "1X30 CAPS IN HDPE BOTTLE" },
  { id: 8, GENERIC_NAME: "SALMETEROL 50 MCG & FLUTICASONE PROPIONATE 500 MCG DRY POWDER FOR INHALATION", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 9, GENERIC_NAME: "TIOTROPIUM (18MCG)", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 10, GENERIC_NAME: "TITROPIUM BROMIDE 18MCG + FORMETROL FUMORATE 12MCG", PACK_STYLE: "30 CAP HDPE BOTTLE" },
  { id: 11, GENERIC_NAME: "TOITROPIUM BROMIDE POWDER FOR INHALATION 18MCG", PACK_STYLE: "30 CAP HDPE BOTTLE" },
];



const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>PACK_STYLE</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.PACK_STYLE}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function RotaCaps() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>ROTACAPS</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={rotaData} />
        </div>
      </div>

    </div>
  )
}

