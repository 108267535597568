import React from "react";

import ProductMain from '../../../../elements/ProductMain';

const medicines = [
  {
    subtitle:"Tablets",
    subproducts:[
      {
        name:"Acelofenac & Paracetamol Tablets",
        image: require("../../../../assets/eternal/tablet1.jpg")  
      },
      {
        name:"Abacavir + Lamivudine  Tablets",
        image: require("../../../../assets/eternal/tablet2.jpg")  
      },
      {
        name:"Acetazolamide Tablets",
        image: require("../../../../assets/eternal/tablet3.jpg")  
      },
      {
        name:"Bosentan Tablets",
        image: require("../../../../assets/eternal/tablet4.jpg")  
      },
    ]
  },
  {
    subtitle:"Capsules",
    subproducts:[
      {
        name:"Acebrophylline Capsule",
        image: require("../../../../assets/eternal/capsule1.jpg")  
      },
      {
        name:"Cephalexin Capsule ",
        image: require("../../../../assets/eternal/capsule2.jpg")  
      },
      {
        name:"Isotretinoin  Capsule",
        image: require("../../../../assets/eternal/capsule3.jpg")  
      },
      {
        name:"Omeprazole Capsule",
        image: require("../../../../assets/eternal/capsule4.jpg")  
      },
    ]
  }
]

export default function EnterFormulations() {
  return (
    <div>
      <h1 className="bg-green-200 p-[1vh] px-[3vh] text-[2.5vh] lg:text-[3vh]">
        Enternal Formulations
      </h1>
      {medicines.map((medicine,index)=>(
        <ProductMain 
          key={index}
          subtitle={medicine.subtitle}
          subcategories={medicine.subproducts}
        />
      ))}  
    </div>
  );
}
