

import React from 'react'

export const optinstrumentData = [
  { id: 1, GENERIC_NAME: "KERATOME BLADES", TYPES: ["2.0MM", "2.2MM", "2.4MM", "2.6MM", "2.65MM", "2.75MM", "2.8MM", "3.0MM", "3.2MM"] },
  { id: 2, GENERIC_NAME: "SIDE PORT / LANCE TIP BLADES", TYPES: ["15 DEGREE", "30 DEGREE", "ETC"] },
  { id: 3, GENERIC_NAME: "CRESCENT BLADES", TYPES: [] },
  { id: 4, GENERIC_NAME: "CLEAR CORNEA BLADES", TYPES: ["2.0MM", "2.2MM", "2.4MM", "2.6MM", "2.65MM", "2.75MM", "2.8MM", "3.0MM", "3.2MM"] },
  { id: 5, GENERIC_NAME: "MVR BLADES", TYPES: ["19G", "20G"] },
  { id: 6, GENERIC_NAME: "ENLARGER BLADES", TYPES: [] },
  { id: 7, GENERIC_NAME: "SINGLE BEVEL BLADES OR DOUBLE BEVEL BLADES", TYPES: [] },
  { id: 8, GENERIC_NAME: "DEPTH CONTROL BLADES", TYPES: [] },
  { id: 9, GENERIC_NAME: "HYDRODISSECTORS", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 10, GENERIC_NAME: "CYSTOTOMES", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 11, GENERIC_NAME: "VISCO CANNULA", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 12, GENERIC_NAME: "ANTERIOR CHAMBER CANNULA", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 13, GENERIC_NAME: "SIMCOE CANNULA WITH SILICON TUBING", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 14, GENERIC_NAME: "RETROBULBAR CANNULA", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 15, GENERIC_NAME: "PERIBULBAR CANNULA", TYPES: ["REUSABLE", "DISPOSABLE"] },
  { id: 16, GENERIC_NAME: "OPHTHALMIC DRAPES", TYPES: ["SURGICAL SUPPLY"] },
  { id: 17, GENERIC_NAME: "MASKS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 18, GENERIC_NAME: "TROLLEY COVERS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 19, GENERIC_NAME: "OT-CAPS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 20, GENERIC_NAME: "FOOT-COVERS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 21, GENERIC_NAME: "GOGGLES", TYPES: ["SURGICAL SUPPLY"] },
  { id: 22, GENERIC_NAME: "SURGEON GOWNS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 23, GENERIC_NAME: "STERILIZATION TRAYS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 24, GENERIC_NAME: "EYE SHIELDS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 25, GENERIC_NAME: "SPEARS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 26, GENERIC_NAME: "EYE PADS", TYPES: ["SURGICAL SUPPLY"] },
  { id: 27, GENERIC_NAME: "OCULAR PROSTHESIS / ARTIFICIAL EYES", TYPES: ["SURGICAL SUPPLY"] },
];




const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>TYPES</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.TYPES}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Optinstrument() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>OPTHALMIC CONSUMABLES & INSTRUMENTS</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={optinstrumentData} />
        </div>
      </div>

    </div>
  )
}


