import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import search from "../../assets/search.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { searchProducts } from "../Result/Search";
import Result from "../Result/Result";

export default function Navbar() {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  const handleSearch = async () => {
    const results = searchProducts(searchTerm);

    

    setSearchResults(results);
  };

  useEffect(() => {
    setSearchResults([]); // Clear searchResults when the route changes
  }, [location.pathname]);


  return (
    <>
      <div className="navbar pt-[0vhz] md:pt-[2vh] pb-[0vh] md:pb-[3vh]">
        <div className="w-full md:w-[90%] mx-auto pt-[1vh]">
          <div className="p-[2vh] flex flex-col md:flex-row justify-between">
            <div className="flex flex-row justify-between items-center">
              <img
                src={logo}
                className="w-[20vh] md:w-[20vw] lg:w-[15vw]"
                alt="pelikaslogo"
              />

              <div
                className={`hamburger ${isOpen ? "open" : ""}`}
                onClick={toggleNav}
              >
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div
              className={`search w-[85%] md:w-[60%] h-[5vh] flex flex-row mt-[5vh] md:mt-[0vh] mx-auto md:mx-[0vh] ${isOpen ? "visible" : "hidden"
                }`}
            >
              <input
                type="text"
                className="searchbar h-[5vh] w-[90%] px-[2vh] focus:outline-none mr-[1vh]"
                placeholder="Search Products, medicines etc"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <Link
                to={`/result?query=${encodeURIComponent(searchTerm)}`}
                className="searchbtn flex flex-row justify-center items-center w-[5vh] bg-black"
              >
                <img
                  src={search}
                  alt=""
                  className="w-[3vh] h-[3vh]"
                  onClick={handleSearch}
                />
              </Link>

              <ToastContainer toastClassName="custom-toast" />
            </div>
          </div>

          <div className="mt-[1.5vh]">
            <ul
              className={`nav-links flex flex-col items-center md:flex-row justify-around ${isOpen ? "visible" : "hidden"
                }`}
            >
              <li
                className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                <Link to="/" data-tab="home" onClick={closeNav}>
                  Home
                </Link>
              </li>

              <li
                className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/about" ? "active" : ""
                  }`}
              >
                <Link to="/about" onClick={closeNav}>
                  About Us
                </Link>
              </li>

              <li
                className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/our-expertise" ? "active" : ""
                  }`}
              >
                <Link to="/our-expertise" onClick={closeNav}>
                  Our Expertise
                </Link>
              </li>

              <li
                className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/products" ? "active" : ""
                  }`}
              >
                <Link to="/products" onClick={closeNav}>
                  Products-Portfolio
                </Link>
              </li>

              <li
                className={`text-[2.3vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/services" ? "active" : ""
                  }`}
              >
                <Link to="/services" onClick={closeNav}>
                  Services
                </Link>
              </li>

              <li
                className={`text-[2.3vh] mb-[3vh] md:mb-[0vh] mt-[1vh] md:mt-[0vh] md:text-[2.3vh] lg:text-[2.7vh] font-semibold text-[gray] ${location.pathname === "/contact" ? "active" : ""
                  }`}
              >
                <Link to="/contact" onClick={closeNav}>
                  Contact
                </Link>
              </li>
            </ul>

            <div
              className={`underline-bar ${location.pathname === "/"
                  ? "home"
                  : location.pathname.substring(1)
                }`}
            ></div>
          </div>
        </div>
      </div>
      
      {searchResults.length > 0 && <Result searchResults={searchResults} />}

<ToastContainer toastClassName="custom-toast" />

    </>
  );
}
