

import React from 'react'

export const implantData = [
  { id: 1, GENERIC_NAME: "PMMA RIGID INTRAOCULAR LENSES", CATEGORY: "LENSES" },
  { id: 2, GENERIC_NAME: "HYDROPHILIC FOLDABLE INTRAOCULAR LENSES", CATEGORY: "LENSES" },
  { id: 3, GENERIC_NAME: "HYDROPHOBIC FOLDABLE INTRAOCULAR LENSES", CATEGORY: "LENSES" },
  { id: 4, GENERIC_NAME: "DELIVERY SYSTEMS FOR FOLDABLE INTRAOCULAR LENSES", CATEGORY: "LENSES" },
  { id: 5, GENERIC_NAME: "PMMA INTRAOCULAR LENSES WITH ASPHERIC OPTIC", CATEGORY: "LENSES" },
  { id: 6, GENERIC_NAME: "YELLOW PPMA INTRAOCULAR LENSES", CATEGORY: "LENSES" },
  { id: 7, GENERIC_NAME: "MULTIFOCAL INTRAOCULAR LENS", CATEGORY: "LENSES" },
  { id: 8, GENERIC_NAME: "LISSAMINE GREEN DIAGNOSTIC TEST STRIPS", CATEGORY: "TEST STRIPS" },
  { id: 9, GENERIC_NAME: "FLUORESCEIN OPHTHALMIC DIAGNOSTIC TEST STRIPS", CATEGORY: "TEST STRIPS" },
  { id: 10, GENERIC_NAME: "SCHIRMER OPHTHALMIC DIAGNOSTIC TEST STRIPS", CATEGORY: "TEST STRIPS" },
  { id: 11, GENERIC_NAME: "ROSE BENGAL OPHTHALMIC DIAGNOSTIC TEST STRIPS", CATEGORY: "TEST STRIPS" },
];





const Table = ({ data }) => {
  // const tableStyle = {

  // };

  return (
    <table className='w-[100%] h-[50vh] rounded-2xl '>
      <thead className='bg-[#62ff0053]'>
        <tr >
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>Sr no.</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>GENERIC_NAME</th>
          <th className='p-[1vh] border-[0.1vh] border-black text-center '>CATEGORY</th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {data.map((item) => (
          <tr key={item.id}>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.id}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.GENERIC_NAME}</td>
            <td className='p-[1vh] border-[0.1vh] border-black text-center '>{item.CATEGORY}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

}

export default function Implants() {
  return (
    <div>
      <h1 className='text-center p-[0.8vh] text-[2.5vh] lg:text-[4vh] mb-[3vh]'>IMPLANTS / LENSES</h1>
      <div className='h-[60vh] overflow-scroll'>
        <div className="App">
          <Table data={implantData} />
        </div>
      </div>

    </div>
  )
}


