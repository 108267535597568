export const liquidData = [
    {
      id: 1,
      GENERIC_NAME: "ADRENALINE INJ.",
      PACK_STYLE: "1MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 2,
      GENERIC_NAME: "ALPHA BETA ARTEETHER INJ.",
      PACK_STYLE: "150MG/2ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 3,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      PACK_STYLE: "100MG / 2ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 4,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      PACK_STYLE: "250MG / 2ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 5,
      GENERIC_NAME: "AMIKACIN SULPHATE INJ.",
      PACK_STYLE: "500MG / 2ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 6,
      GENERIC_NAME: "ARTEMETHER INJ",
      PACK_STYLE: "80MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 7,
      GENERIC_NAME: "ATACURIUM BESYLATE",
      PACK_STYLE: "25 MG/2.5 ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 8,
      GENERIC_NAME: "ATRACURIUM BESYLATE",
      PACK_STYLE: "50 MG/5 ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 9,
      GENERIC_NAME: "ATROPINE SULPHATE INJ.",
      PACK_STYLE: "0.6MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 10,
      GENERIC_NAME: "BETAMETHASONE SODIUM PHOSPHATE INJ.",
      PACK_STYLE: "4MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 11,
      GENERIC_NAME: "BUPIVACAINE DEXTROSE INJ. 4 ML",
      PACK_STYLE: "5MG/ML AND 80MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 12,
      GENERIC_NAME: "BUPIVACAINE INJ. 20 ML",
      PACK_STYLE: "5MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 13,
      GENERIC_NAME: "CHLORAMPHENICOL SODIUM SUCCINATE",
      PACK_STYLE: "1 GM",
      CATEGORY: "GENERAL",
    },
    {
      id: 14,
      GENERIC_NAME: "CHLOROQUINE PHOSPHATE INJ. 30ML",
      PACK_STYLE: "40MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 15,
      GENERIC_NAME: "CHLOROQUINE PHOSPHATE INJ. 5ML",
      PACK_STYLE: "40MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 16,
      GENERIC_NAME: "CHLORPHENIRAMINE MALEATE INJ.",
      PACK_STYLE: "10MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 17,
      GENERIC_NAME: "CHLORPROMAZINE INJ",
      PACK_STYLE: "25MG/ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 18,
      GENERIC_NAME: "CITICHOLINE",
      PACK_STYLE: "250MG/ML- 2ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 19,
      GENERIC_NAME: "CITICHOLINE",
      PACK_STYLE: "250MG/ML- 4ML",
      CATEGORY: "GENERAL",
    },
    {
      id: 20,
      GENERIC_NAME: "CLINDAMYCIN",
      PACK_STYLE: "300 MG",
      CATEGORY: "GENERAL",
    },
    // Add more entries as needed
  ];

  export default liquidData;