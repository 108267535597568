import React, { useState, useEffect } from 'react'

export default function ReloadCounter2({finalCount,title,gradient}) {
  
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = finalCount;
    const duration = 2000; // Duration of count in milliseconds

    if (start === end) return;

    const stepTime = Math.abs(Math.floor(duration / end));
    const timer = setInterval(() => {
      start += 25;
      if (start >= end) {
        clearInterval(timer);
        start = end;
      }
      setCount(start);
    }, stepTime);

    return () => clearInterval(timer); // Clear interval on unmount
  }, [finalCount]);


  return (
    <div 
      className={`w-[32vh] mb-[2vh] text-center bg-gradient-to-br ${gradient} p-[4vh] rounded-3xl`}
    >
      <h1 className="text-[3vh] md:text-[4vh]">{count}+</h1>
      <p className="text-[2.2vh] md:text-[2.5vh]">{title}</p>
    </div>
  )
}
