// Result.js

import React from "react";

const Result = ({ searchResults }) => {
  return (
    <div className="w-[80%] mx-auto my-[6vh]">
      {searchResults && searchResults.length > 0 ? (
        <div className="search-results">
          <div className="w-full overflow-scroll">
            <table className="border-collapse border border-black w-full">
              <thead className="bg-[#62ff0053]">
                <tr>
                  <th className="p-[1vh] border-[0.1vh] border-black text-center  ">Sr No</th>
                  <th className="p-[1vh] border-[0.1vh] border-black text-center  ">GENERIC_NAME</th>
                  <th className="p-[1vh] border-[0.1vh] border-black text-center  ">CATEGORY/PACK_STYLE</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, index) => (
                  <tr key={result.id} className="text-[3vh] my-[1vh]">
                    <td className="p-[1vh] border-[0.1vh] border-black text-center">{index + 1}</td>
                    <td className="p-[1vh] border-[0.1vh] border-black text-center">{result.GENERIC_NAME}</td>
                    <td className="p-[1vh] border-[0.1vh] border-black text-center">
                      {result.CATEGORY || result.PACK_STYLE}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="notfound">
          <h1 className="text-[3vh] my-[1vh]">No products found.</h1>
        </div>
      )}
    </div>
  );
};

export default Result;
